import { Container, Section, Title } from 'components';
import { WE_BUILD_DATA } from 'data';
import { useNav } from 'hooks/useNav';
import { MainSections } from 'pages/main/main.enum';

export function WeBuildSection() {
  const ref = useNav(MainSections.WeBuild);

  return (
    <Section ref={ref} className='we-play h-unset bg-gray-900'>
      <Title className='mb-12'>We build</Title>

      <Container>
        <div className='sm:grid sm:grid-cols-2 sm:gap-4 md:grid-cols-3'>
          {WE_BUILD_DATA.map((game, index) => (
            <a
              className={`card card-compact relative mb-4 bg-base-100 shadow-xl before:z-10 before:col-start-1 before:row-start-1 before:rounded-lg before:bg-slate-900 before:opacity-50 hover:bottom-0.5 before:hover:opacity-60 sm:mb-0 ${
                game.soon ? 'pointer-events-none' : ''
              }`}
              href={game.link}
              target='_blank'
              rel='noopener noreferrer'
              key={`game-${index}`}
            >
              {game.soon && (
                <div className='absolute right-1/2 top-4 z-10 translate-x-1/2 rounded-sm bg-black/50 px-2 py-1 text-primary'>
                  Coming soon...
                </div>
              )}

              <figure
                className={`relative col-start-1 row-start-1 overflow-visible rounded-lg ${
                  game.soon ? 'grayscale' : ''
                }`}
              >
                <img className='h-full object-cover' src={game.image} alt={game.name} />
                <div className='absolute bottom-0 left-0 right-0 top-0 flex items-end justify-center text-sm'>
                  {Object.entries(game.features).map(([name, description]) => (
                    <div className='tooltip m-1 rounded-sm bg-black/20 px-2 py-1' data-tip={description} key={name}>
                      {name}
                    </div>
                  ))}
                </div>
              </figure>
              <div
                className={`card-body relative z-20 col-start-1 row-start-1 rounded-lg drop-shadow ${
                  game.soon ? 'grayscale' : ''
                }`}
                style={{ border: `2px solid ${game.color}` }}
              >
                <h2 className='card-title justify-center' style={{ color: game.color }}>
                  {game.name}
                </h2>
                {/* <p>{game.desc}</p> */}
              </div>
            </a>
          ))}
        </div>
      </Container>
    </Section>
  );
}
