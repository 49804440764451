import LogoIcon from 'assets/images/logo-long-white.svg';
import { Social } from 'components';
import { useNav } from 'hooks/useNav';
import { MainSections } from 'pages/main/main.enum';
import { HeroBackground } from '../components';
import { SOCIAL_DATA } from 'data';
import AsciiImage from 'components/ascii-image';
import asciiLogo from 'assets/images/logo-long-white.png';

export function HeroSection() {
  const ref = useNav(MainSections.Hero);
  const [twitter, discord, github] = SOCIAL_DATA;

  return (
    <div ref={ref} className='relative flex min-h-screen justify-center'>
      <HeroBackground />
      <div className='relative m-auto max-w-5xl p-4 text-center'>
        <div className='relative m-auto max-w-[400px]'>
          <img src={LogoIcon} className='animate-fadeIn delay-2000 duration-2000 w-full opacity-0' />
          <div className='animate-fadeOut delay-2000 duration-2000 pointer-events-none absolute left-1/2 top-1/2 z-10 -ml-2 -mt-4 -translate-x-1/2 -translate-y-1/2'>
            <AsciiImage imageUrl={asciiLogo} size={70} />
          </div>
        </div>

        <div className='animate-fadeIn duration-2000 delay-2500 opacity-0'>
          <h1 className='glitch font-secondary mb-12 mt-7 text-5xl font-semibold uppercase text-primary'>
            <span aria-hidden='true'>playing the longest game</span>
            playing the longest game
            <span aria-hidden='true'>playing the longest game</span>
          </h1>
        </div>

        <div className='socials mt-10 flex justify-center gap-2'>
          <div className='animate-fadeIn duration-2000 delay-3000 opacity-0'>
            <Social icon={twitter.icon} link={twitter.link} className='kbd-lg' />
          </div>
          <div className='animate-fadeIn duration-2000 delay-3500 opacity-0'>
            <Social icon={discord.icon} link={discord.link} className='kbd-lg' />
          </div>
          <div className='animate-fadeIn duration-2000 delay-4000 opacity-0'>
            <Social icon={github.icon} link={github.link} className='kbd-lg' />
          </div>
        </div>
      </div>
    </div>
  );
}
