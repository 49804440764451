import yanik from 'assets/images/team/yanik.jpg';
import dudendy from 'assets/images/team/dudendy.png';
import cj from 'assets/images/team/cj.png';
import stx from 'assets/images/team/stx.png';
import { SocialProps } from './social.data';

export interface Member {
  name: string;
  id: number;
  image: string;
  title: string;
  socials: SocialProps[];
}

export const TEAM_DATA: Member[] = [
  {
    name: 'yanik',
    id: 4751,
    image: yanik,
    title: 'Ethereal Expansionist',
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/yanik_ai'
      }
    ]
  },
  {
    name: 'dudendy',
    title: 'dev warlock',
    id: 7788,
    image: dudendy,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/dudendy'
      },
      {
        icon: 'github',
        link: 'https://github.com/dudendy'
      }
    ]
  },
  {
    name: 'classicJ',
    title: 'strategy architect',
    id: 3672,
    image: cj,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/ClassicJordon'
      }
    ]
  },
  {
    name: '9stx6',
    title: 'dev zealot',
    id: 23470,
    image: stx,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/PavelRudolf3'
      },
      {
        icon: 'github',
        link: 'https://github.com/Stx69'
      }
    ]
  }
];
