import { PageNav } from 'components/pageNav/pageNav.component';
import { PageNavContext } from 'contexts';
import { CommonUtils } from 'utils';
import { MainSections } from './main.enum';

import { HeroSection, TeamSection, WeBuildSection, WePlaySection } from './sections';
import { memo, useContext, useMemo } from 'react';

const navList = Object.values(MainSections);

const Section = memo(({ children, sectionName }: { children: JSX.Element; sectionName: MainSections }): JSX.Element => {
  const { sectionId } = useContext(PageNavContext);

  const isActive = useMemo(
    () =>
      CommonUtils.stringToKey(MainSections[CommonUtils.removeWhitespace(sectionName)]) === sectionId ? 'active' : '',
    [sectionId, sectionName]
  );

  return (
    <section
      id={`${CommonUtils.stringToKey(MainSections[CommonUtils.removeWhitespace(sectionName)])}-section`}
      className={isActive}
    >
      {children}
    </section>
  );
});

export function Main() {
  return (
    <div>
      <PageNav navList={navList} />
      <Section sectionName={MainSections.Hero}>
        <HeroSection />
      </Section>
      <Section sectionName={MainSections.WeBuild}>
        <WeBuildSection />
      </Section>
      <Section sectionName={MainSections.WePlay}>
        <WePlaySection />
      </Section>
      {/* <Section sectionName={MainSections.Lore}>
        <LoreSection />
      </Section> */}
      <Section sectionName={MainSections.Team}>
        <TeamSection />
      </Section>
    </div>
  );
}
